import { Button, CircularProgress, DialogActions, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { DateRangePicker } from 'materialui-daterange-picker';
import moment from 'moment'
import { toast } from 'react-toastify'

const StatementExportDialog = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { formatMessage } = useIntl()
  const defaultDateRange = useRef({
    startDate: moment().subtract(6, 'month').toDate(),
    endDate: new Date(),
  }).current
  const [fileType, setFileType] = useState('PDF')
  const [dateRange, setDateRange] = useState(defaultDateRange)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      await onSubmit({
        fileType,
        startDate: moment(dateRange.startDate.getTime()).format('YYYY-MM-DD'),
        endDate: moment(dateRange.endDate.getTime()).format('YYYY-MM-DD'),
      });
    } catch {
      toast.error(formatMessage({ id: 'tlpay.failed-to-download-statement' }))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!dateRange) return

    const startDate = dateRange.startDate.getTime()
    const endDate = dateRange.endDate.getTime()
    const minDate = moment(endDate).subtract(6, 'month').toDate().getTime()

    if (startDate < minDate) {
      setErrorMessage(formatMessage({ id: 'tlpay.the-selected-date-range-exceeds-the-maximum-limit-of-six-months' }))
    } else {
      setErrorMessage(null)
    }
  }, [dateRange, formatMessage, setErrorMessage])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogContent>
        <Box pb={2}>
          <Typography component="h5" variant="h5">
            {formatMessage({ id: 'tlpay.choose-account' })}
          </Typography>
        </Box>
        <Box flexGrow={1}>
          <Box>
            <FormControl>
              <RadioGroup
                value={fileType}
                onChange={e => setFileType(e.target.value)}
              >
                <Box display="flex" gap="1rem">
                  <FormControlLabel
                    value="PDF"
                    control={<Radio />}
                    label={formatMessage({ id: 'tlpay.pdf' })}
                  />
                  <FormControlLabel
                    value="CSV"
                    control={<Radio />}
                    label={formatMessage({ id: 'tlpay.csv' })}
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>

          <Box display="flex" m={4}>
            <Box>
              <DateRangePicker
                open={true}
                toggle={() => {}}
                closeOnClickOutside={false}
                initialDateRange={defaultDateRange}
                maxDate={new Date()}
                onChange={setDateRange}
                definedRanges={[]}
              />

              <Box mt={2}>
                <Typography variant="caption" color='error'>
                  {errorMessage || <span>&nbsp;</span>}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
          color="default"
          disabled={isLoading}
        >
          {formatMessage({ id: 'tlpay.cancel' })}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          disabled={isLoading || errorMessage}
        >
          {
            isLoading
              ? <CircularProgress size="1rem" />
              : formatMessage({ id: 'tlpay.confirm-export' })
          }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

StatementExportDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default StatementExportDialog
