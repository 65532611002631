import React from 'react'
import PropTypes from 'prop-types'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import Button from '@material-ui/core/Button'
import { useStyles } from './styles'
import { useIntl } from 'react-intl'

const ExportStatementButton = ({ onClick }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  return (
    <Button
      variant="contained"
      color="secondary"
      size="small"
      onClick={onClick}
      startIcon={<DownloadIcon />}
      className={classes.root}
    >
      {formatMessage({ id: 'table-filters.export-statement' })}
    </Button>
  )
}

ExportStatementButton.propTypes = {
  onClick: PropTypes.func
}

export default ExportStatementButton
