/* eslint-disable no-shadow */
/* eslint-disable prefer-spread */
import {
  Typography, Paper,
  FormControlLabel, Checkbox,
} from '@material-ui/core'
import React, { useState } from 'react'
import Button from 'components/Button'
import { styled } from '@material-ui/core/styles'
import { Controller, useFormContext } from 'react-hook-form'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { EWALLET_PROVIDER } from 'constants.js'
import LinkButton from 'components/LinkButton'
import DownloadIcon from '@material-ui/icons/GetApp'
import { useIntl } from 'react-intl'

const Group = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2)
}))

const PDFView = styled('div')(({ theme }) => ({
  height: '60vh',
  marginBottom: theme.spacing(1),
  overflowY: 'auto',
}))

const FlexRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}))

const ProviderTermsAndConditions = ({ goToNextStep, provider }) => {
  const { control, watch } = useFormContext()
  const fileUrl = `/terms-and-conditions-tlpay-provider-${
    provider === EWALLET_PROVIDER.NIUM_EU ? 'eu' : 'uk'}.pdf`
  const [numPages, setNumPages] = useState(null)
  const { formatMessage } = useIntl()

  const canContinue = () => {
    if (!watch("tos.providerAccept", false)) {
      return false
    }
    return true
  }

  return (
    <>
      <Typography variant='h3' gutterBottom>
        {formatMessage({ id: 'tlpay.terms-and-conditions' })}
      </Typography>
      <Typography paragraph>
        {formatMessage({ id: 'tlpay.please-review-and-accept-the-travel-ledger-t-c-addendum-relating-to-the-tl-pay-service-as-well-as-the-terms-and-conditions-from-our-partner-nium-who-will-provide-you-with-the-underlying-banking-service-powering-tl-pay' })}
      </Typography>
      <Group variant='outlined'>
        <PDFView>
          <Document
            file={fileUrl}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map((page) => (
                <Page
                  key={`page_${page}`}
                  pageNumber={page}
                  scale={1.5}
                  size="A4"
                />
              ))
            }
          </Document>
        </PDFView>
        <FlexRow>
          <Controller
            name="tos.providerAccept"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox
                  {...field}
                  checked={field.value}
                  color="primary"
                  defaultValue={false}
                  onChange={(e) => field.onChange(e.target.checked)}
                />}
                label={formatMessage({ id: 'tlpay.i-agree-to-the-tl-pay-terms-and-conditions' })}
              />
            )}
          />
          <LinkButton
            color="primary"
            href={fileUrl}
            target='_blank'
            icon={<DownloadIcon />}
            iconPosition='end'
            label={formatMessage({ id: 'tlpay.download-travel-ledger-t-cs-pdf' })}
          />
        </FlexRow>
      </Group>
      <Button
        color="primary"
        onClick={goToNextStep}
        disabled={!canContinue()}
      >
        {formatMessage({ id: 'tlpay.continue' })}
      </Button>
    </>
  )
}

export default ProviderTermsAndConditions
