import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DataGrid from 'components/DataGrid'
import { RefreshButton } from 'components/DataGrid/components/ActionButtons'
import { DEFAULT_COLUMN_WIDTH_VALUES } from 'components/DataGrid/utils'
import { filterValuesToQueryParams, parseTransactions, filterLocalTransactions } from '../utils'
import { initialFilterValues, TRANS_TYPES, TRANS_STATUS } from '../constants'
import { useQuery } from 'react-query'
import { get } from 'utils/api'
import { API_URL } from 'constants.js'
import { FILTER_TYPES } from 'components/DataGrid/utils'
import { toast } from 'react-toastify'
import { getReadableErrorMessage } from 'utils/errors'
import { useIntl } from 'react-intl'
import { kebabCase } from 'lodash'
import StatementExportDialog from './StatementExportDialog'
import ExportStatementButton from 'components/DataGrid/components/ActionButtons/ExportStatementButton'

const Transactions = ({ accountSelected, setTriggerRefetchBalances, companyWallet }) => {
  const [filters, setFilters] = useState(initialFilterValues)
  const [queryParams, setQueryParams] = useState(filterValuesToQueryParams(filters, accountSelected))
  const { companyId, timezone } = companyWallet || {}
  const { formatMessage } = useIntl()
  const [isStatementExportDialogOpened, setIsStatementExportDialogOpened] = useState(false)

  const { 
    data: transactionsData, 
    isLoading: IsLoadingTransactions, 
    refetch: refetchTransactions 
  } = useQuery(
    'transactions', 
    () => get(`${API_URL}/e-wallet/transactions/${companyId}`, queryParams), 
    {
      enabled: false, // wait for accountSelected currency to be setted
      retry: false,
      onError: (error) => toast.error(getReadableErrorMessage(error))
    }
  )

  const columns = [
    {
      // ...useGridTitleWithHelpPopup({ id: 'companies.trans-type' }),
      title: formatMessage({ id: `transactions.type` }),
      name: 'transactionType',
      filter: {
        type: FILTER_TYPES.SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(TRANS_TYPES)
              .map(([k, v]) => [k, formatMessage({ id: `trans-types.${kebabCase(v)}` })])
          ),
        },
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.XL
    },
    {
      // ...useGridTitleWithHelpPopup({ id: 'companies.trans-type' }),
      title: formatMessage({ id: `transactions.status` }),
      name: 'status',
      filter: {
        type: FILTER_TYPES.SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(TRANS_STATUS)
              .map(([k, v]) => [k, formatMessage({ id: `trans-status.${kebabCase(v)}` })])
          ),
        },
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.XS
    },
    {
      // ...useGridTitleWithHelpPopup({ id: 'companies.amount' }),
      title: formatMessage({ id: `transactions.amount` }),
      name: 'amount',
      getCellValue: (row) => row.debit 
        ? `-${row.amount}`
        : `+${row.amount}`,
      filter: { type: FILTER_TYPES.CURRENCY },
      align: 'right',
      width: DEFAULT_COLUMN_WIDTH_VALUES.XS
    },
    {
      // ...useGridTitleWithHelpPopup({ id: 'companies.date' }),
      title: formatMessage({ id: `transactions.date` }),
      name: 'date',
      filter: { type: FILTER_TYPES.DATE },
      type: 'datetime',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      // ...useGridTitleWithHelpPopup({ id: 'companies.ref' }),
      title: formatMessage({ id: `transactions.trx-id` }),
      name: 'retrievalReferenceNumber',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      // ...useGridTitleWithHelpPopup({ id: 'companies.ref' }),
      title: formatMessage({ id: `transactions.reference` }),
      name: 'reference',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      // ...useGridTitleWithHelpPopup({ id: 'companies.ref' }),
      title: formatMessage({ id: `transactions.payer` }),
      name: 'payer',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      // ...useGridTitleWithHelpPopup({ id: 'companies.ref' }),
      title: formatMessage({ id: `transactions.method` }),
      name: 'method',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      // ...useGridTitleWithHelpPopup({ id: 'companies.commercial-link' }),
      title: formatMessage({ id: `transactions.remittance` }),
      name: 'remittanceId',
      filter: true
    },
    {
      // ...useGridTitleWithHelpPopup({ id: 'companies.commercial-link' }),
      title: formatMessage({ id: `transactions.commercial-link` }),
      name: 'commercialLinkDisplayName',
      filter: true
    },
    {
      title: formatMessage({ id: `transactions.balance` }),
      name: 'currentBalance',
      type: 'currency',
      filter: { type: FILTER_TYPES.CURRENCY },
      getCellValue: (row) => row.currentBalance * 100,
      align: 'right',
      width: DEFAULT_COLUMN_WIDTH_VALUES.XS
    },
  ]

  const transactions = transactionsData || []
  const parsedTransactions = parseTransactions(transactions?.content || [], timezone)?.map((t) => ({
    ...t,
    currentBalance: t.debit
      ? t.previousBalance - t.cardTransactionAmount
      : t.status === TRANS_STATUS.APPROVED ? t.previousBalance + t.cardTransactionAmount : t.previousBalance
  }))
  const filteredTransactions = filterLocalTransactions(parsedTransactions, filters)

  // refetch on filter change
  useEffect(() => {
    if (accountSelected !== 0) {
      setQueryParams(filterValuesToQueryParams(filters, accountSelected))
    }
  }, [filters, accountSelected])

  useEffect(() => {
    refetchTransactions()
  }, [queryParams, refetchTransactions]) // needs to fire after query params state is updated

  const downloadStatement = async (startDate, endDate, currency, fileType) => {
    const blob = await get(`${API_URL}/e-wallet/statements/${encodeURIComponent(companyId)}/download`, {
      startDate, // EXAMPLE: '2024-12-01'
      endDate, // EXAMPLE: '2025-01-01'
      currency, // EXAMPLE: 'GBP'
      fileType, // EXAMPLE: 'PDF'
    }, { responseType: 'blob' })

    const a = document.createElement('a')
    a.setAttribute('target', '_blank')
    a.setAttribute('href', URL.createObjectURL(blob))
    a.click()
  }

  const handleStatementExport = async ({ startDate, endDate, fileType }) => {
    const currency = accountSelected?.curSymbol
    await downloadStatement(startDate, endDate, currency, fileType)
  }

  return (<>
    <DataGrid
      virtualTable
      data={filteredTransactions || []}
      name={'transactions'}
      fullRemainingWindowHeight
      isLoading={IsLoadingTransactions}
      filtering={{
        defaultFilters: initialFilterValues,
        filters,
        onChange: (newValue) => {
          setFilters(newValue)
        },
        clearFilters: () => {
          setFilters(initialFilterValues)
        }
      }}
      columns={columns}
      pagination={{
        onPageChanged: (e) => {
          setFilters({
            currentPage: e.currentPage,
            itemsPerPage: e.pageSize
          })
        },
        totalNumberOfItems: transactions?.totalElements || 0,
      }}
      extra={<>
        <ExportStatementButton onClick={() => setIsStatementExportDialogOpened(true)} />
        <RefreshButton onClick={() => {
          refetchTransactions()
          setTriggerRefetchBalances()
        }} />
      </>}
      defaultHiddenColumns={[ 'retrievalReferenceNumber' ]}
    />

    {isStatementExportDialogOpened && (
      <StatementExportDialog
        open={isStatementExportDialogOpened}
        onClose={() => setIsStatementExportDialogOpened(false)}
        onSubmit={handleStatementExport}
      />
    )}
  </>)
}

Transactions.propTypes = {
  accountSelected: PropTypes.object,
  setTriggerRefetchBalances: PropTypes.func,
  companyWallet: PropTypes.object,
}

export default Transactions
