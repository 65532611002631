import { useStyles } from '../styles'
import React, {
  useState,
  useEffect,
  useRef,
} from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import { Typography } from '@material-ui/core'
import FormSelect from 'components/FormSelect'
import { useIntl } from 'react-intl'

const SelectComponent = ({ nameId, formItem: { label, options, required, disabled, info, defaultValue, multiple } }) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const {
    control,
    setValue,
    watch,
  } = useFormContext()
  const [show, setShow] = useState(true)
  const defaultLoadedRef = useRef(false)
  const isOnlyOption = Object.keys(options).length === 1
  const value = watch(nameId)

  useEffect(() => {
    if (!show) {
      setShow(true)
    }
  }, [show])

  useEffect(() => {
    const optionKeys = Object.keys(options)
    if (multiple) {
      const keySet = new Set(optionKeys)
      const fallbackVal = required && isOnlyOption ? Object.keys(options) : []

      if (!defaultLoadedRef.current && defaultValue) {
        setValue(nameId, (Array.isArray(defaultValue) ? defaultValue : fallbackVal).filter(x => keySet.has(x)))
      } else {
        const validValues = (Array.isArray(value) ? value : fallbackVal).filter(x => keySet.has(x))
        if (validValues.length !== value?.length) {
          setValue(nameId, validValues)
        }
      }
    } else if (!defaultLoadedRef.current || !optionKeys.includes(value)) {
      if (isOnlyOption) {
        setValue(nameId, optionKeys[0])
      }
      if(defaultValue) {
        setValue(nameId, defaultValue)
      }
      Object.assign(defaultLoadedRef, { current: true })
    }
  }, [defaultLoadedRef, isOnlyOption, nameId, options, setValue, defaultValue, value, multiple])

  if (!show) {
    return <></>
  }

  return (
    <Controller
      render={({ field, fieldState }) => {
        let errorMessage;
        if (fieldState.error) {
          if (
            / contains an invalid value/.test(fieldState.error.message) ||
            / must be one of \[/.test(fieldState.error.message)
          ) {
            errorMessage = formatMessage({ id: 'kyb-form.invalid-value' })
          } else {
            errorMessage = formatMessage({ id: 'kyb-form.is-required' })
          }
        }

        return (
          <>
            <FormSelect
              {...field}
              {...((
                disabled ||
                (!multiple && isOnlyOption) ||
                (multiple && required && isOnlyOption)
              ) && { disabled: true })}
              multiple={!!multiple}
              label={`${label} ${required ? '*' : ''}`}
              keyTitle={options}
              variant="outlined"
              className={classes.field}
              fullWidth
              dense
              error={errorMessage}
              endAdornment={
                isOnlyOption ? <></> :
                <InputAdornment position="end" className={classes.selectClear}>
                  <IconButton
                    disableRipple
                    onClick={() => {
                      setShow(false)
                      setValue(nameId, '')
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    <ClearIcon fontSize="small" color="disabled" />
                  </IconButton>
                </InputAdornment>
              }
            />
            {info && <Typography color="textSecondary" className={classes.info} dangerouslySetInnerHTML={{ __html: info }} />}
          </>
        )
      }}
      control={control}
      name={nameId}
      error={false}
    />
  )
}

export default SelectComponent