import { 
  businessTypesEu as businessTypes,
  corporateDocumentTypeEu as corporateDocumentType, 
  personDocumentTypeEu as personDocumentType,
  annualTurnoverEu as annualTurnover,
  positionEu as position,
  industrySector,
  totalEmployees,
  intendedUseOfAccount,
  corporateDocumentTypeStakeholder,
  yesOrNo,
  monthlyTransactionVolume,
  monthlyTransactions,
  averageTransactionValue,
  countryOfOperationEu,
} from "../values";
import JoiBase from 'joi'
import JoiDate from '@joi/date'
import { EWALLET_REGION, NIUM_STAKEHOLDER_TYPE } from 'constants.js'

function pathArrayToStr(p) {
  return p.map(x => typeof x === 'number' ? `[${x}]` : `.${x}`).join('').slice(1)
}

const Joi = JoiBase.extend(JoiDate)

const businessDetailsDocumentSchema = () => Joi.array().items(Joi.object({
  documentType: Joi.string().required().custom((value, helpers) => {
    const [rootState] = helpers.state.ancestors.slice(-1)
    const path = pathArrayToStr(helpers.state.path)

    if (path === 'businessDetails.documentDetails[0].documentType') {
      const businessType = rootState.businessDetails.businessType

      if (businessType === 'ASSOCIATION') {
        // Association: Association Deed
        if (value !== 'ASSOCIATION_DEED') {
          return helpers.error('any.invalid')
        }
      } else if (businessType === 'LIMITED_LIABILITY_PARTNERSHIP') {
        // Limited Liability Partnership: Partnership Deed
        if (value !== 'PARTNERSHIP_DEED') {
          return helpers.error('any.invalid')
        }
      } else if (businessType === 'PUBLIC_COMPANY') {
        // Public company: Business Registration Document
        if (value !== 'BUSINESS_REGISTRATION_DOC') {
          return helpers.error('any.invalid')
        }
      } else if (businessType === 'SOLE_TRADER') {
        // Sole Trader: Business Registration Document
        if (value !== 'BUSINESS_REGISTRATION_DOC') {
          return helpers.error('any.invalid')
        }
      } else if (businessType === 'TRUST') {
        // Trust: Trust Deed
        if (value !== 'TRUST_DEED') {
          return helpers.error('any.invalid')
        }
      }
    }

    if (!Object.keys(corporateDocumentType).includes(value)) {
      return helpers.error('any.invalid')
    }
    return value
  }),
  document: Joi.array().items(Joi.object({
    document: Joi.any().required(),
    fileName: Joi.string(),
    fileType: Joi.string(),
  })).required(),
}))

const corporateDocumentSchema = (isStakeholder = false) => Joi.array().items(Joi.object({
  documentType: Joi.string().valid(...Object.keys(isStakeholder ? corporateDocumentTypeStakeholder : corporateDocumentType)).required().empty(''),
  document: Joi.array().items(Joi.object({
    document: Joi.any().required(),
    fileName: Joi.string(),
    fileType: Joi.string(),
  })).required(),
  documentNumber: Joi.string().required().empty(''),
  documentIssuanceCountry: Joi.string().required().empty(''),
}))

const stakeholderPersonDocumentSchema = () => Joi.array().items(Joi.object({
  documentType: Joi.string().empty('').when(Joi.ref('....nationality'), {
    is: Joi.string().valid('LT').required(),
    then: Joi.string().valid(...Object.keys(personDocumentType)).required(),
    otherwise: Joi.string().valid(...Object.keys(personDocumentType).filter(x => x !== 'NATIONAL_ID')).required(),
  }),
  document: Joi.array().items(Joi.custom((value, helpers) => {
    const positionsRequiredDoc = new Set(['UBO', 'TRUSTEE', 'PARTNER', 'SIGNATORY', 'REPRESENTATIVE'])
    const stakeholder = helpers.state.ancestors[
      helpers.state.path.length - 1 - helpers.state.path.findLastIndex(x => x === 'stakeholderDetails')
    ]
    const isDocRequired = !!stakeholder?.stakeholderDetails?.professionalDetails
        ?.map(d => d.position).some(p => positionsRequiredDoc.has(p))

    if (isDocRequired) {
      if (!value) {
        return helpers.error('any.required')
      }

      const result = Joi.object({
        document: Joi.any().required(),
        fileName: Joi.string(),
        fileType: Joi.string(),
      }).required()
        .validate(value)

      if (result?.error) {
        return helpers.error('any.invalid')
      }
    }

    return value
  })).required(),
  documentNumber: Joi.string().required().empty(''),
  documentIssuanceCountry: Joi.string().required().empty(''),
  documentExpiryDate: Joi.date().when('documentType', {
    is: Joi.any().valid('PASSPORT'),
    then: Joi.date().format('YYYY-MM-DD').raw().min('now').empty('').required(),
    otherwise: Joi.date().format('YYYY-MM-DD').raw().min('now').empty(''),
  }),
}))

const applicantPersonDocumentSchema = () => Joi.array().items(Joi.object({
  documentType: Joi.string().empty('').custom((value, helpers) => {
    const [rootState] = helpers.state.ancestors.slice(-1)
    const path = pathArrayToStr(helpers.state.path)

    if (path === 'applicantDetails.documentDetails[0].documentType') {
      if (value !== 'PASSPORT') {
        return helpers.error('any.invalid')
      }
    } else if (
      !rootState?.applicantDetails?.professionalDetails?.map(d => d.position).includes('DIRECTOR') &&
      path === 'applicantDetails.documentDetails[1].documentType'
    ) {
      if (value !== 'POWER_OF_ATTORNEY') {
        return helpers.error('any.invalid')
      }
    } else if (rootState?.applicantDetails?.nationality !== 'LT') {
      // NOTE: the full NIUM requirement is to only accept National ID for Lithuanian citizens (edge case for our users)
      if (
        value === 'NATIONAL_ID' ||
        !Object.keys(personDocumentType).includes(value)
      ) {
        return helpers.error('any.invalid')
      }
    } else if (!Object.keys(personDocumentType).includes(value)) {
      return helpers.error('any.invalid')
    }

    return value
  }),
  document: Joi.array().items(Joi.object({
    document: Joi.any().required(),
    fileName: Joi.string(),
    fileType: Joi.string(),
  }).when(
    // REF: https://joi.dev/api/?v=17.13.3#relative-references
    // NOTE: This is super confusing. The `...` prefix here means grandparent of
    //       the document object at `applicantDetails.documentDetails[0].document[i]`,
    //       which means it will be `applicantDetails.documentDetails[0]`
    Joi.ref('...documentType'),
    {
      is: 'POWER_OF_ATTORNEY',
      then: Joi.required(),
      otherwise: Joi.allow(null).optional(),
    },
  )),
  documentNumber: Joi.string().empty('').when('documentType', {
    is: 'POWER_OF_ATTORNEY',
    then: Joi.allow(null).optional(),
    otherwise: Joi.required(),
  }),
  documentIssuanceCountry: Joi.string().empty('').when('documentType', {
    is: 'POWER_OF_ATTORNEY',
    then: Joi.allow(null).optional(),
    otherwise: Joi.required(),
  }),
  documentExpiryDate: Joi.date().when('documentType', {
    is: Joi.any().valid('PASSPORT'),
    then: Joi.date().format('YYYY-MM-DD').raw().min('now').empty('').required(),
    otherwise: Joi.date().format('YYYY-MM-DD').raw().min('now').empty(''),
  }),
}))

const addressSchema = () => Joi.object({
  addressLine1: Joi.string().required().empty('').max(40).messages({
    'string.max': 'The input value must not exceed 40 characters',
  }),
  addressLine2: Joi.string().empty('').max(40).messages({
    'string.max': 'The input value must not exceed 40 characters',
  }),
  city: Joi.string().required().empty('').max(20).messages({
    'string.max': 'The input value must not exceed 20 characters',
  }),
  country: Joi.string().required().empty(''),
  postcode: Joi.string().required().empty('')
    .min(3).max(10)
    .regex(/^[A-Za-z0-9 ]*$/).messages({
      'string.max': 'Postcode must consist of 3 to 10 characters.',
      'string.pattern.base': 'This input field allows alphanumeric characters and spaces only',
    }),
  state: Joi.string().required().empty('').max(30).messages({
    'string.max': 'The input value must not exceed 30 characters',
  }),
})

const contactSchema = () => Joi.object({
  contactNo: Joi.string().regex(/^\d*$/).required().empty('')
    .max(20)
    .messages({
      'string.max': 'The input value must not exceed 20 digits',
      'string.pattern.base': 'This input field allows digits only',
    }),
  email: Joi.string().email({ tlds: { allow: false } }).required().empty('')
    .max(40)
    .messages({
      'string.max': 'The input value must not exceed 40 characters',
      'string.email': 'The input value is not a valid email address',
    }),
  countryCode: Joi.string().required().empty(''),
})

const professionalDetailsSchema = (isApplicant = false) => Joi.array().items(Joi.object({
  position: Joi.string().required().valid(...Object.keys(position)).empty(''),
  positionStartDate: isApplicant ? 
    Joi.date().format('YYYY-MM-DD').raw().max('now').empty('').required() : 
    Joi.date().when('position', {
      is: Joi.string().valid('UBO', 'PARTNER', 'TRUSTEE').required(),
      then: Joi.date().format('YYYY-MM-DD').raw().max('now').empty('').required(),
      otherwise: Joi.date().format('YYYY-MM-DD').raw().max('now').empty(''),
    }),
  sharePercentage: Joi.number().precision(2).when('position', {
    is: 'UBO',
    then: Joi.number().precision(2).max(100).min(0).required().empty(''),
    otherwise: Joi.number().precision(2).max(100).min(0).optional().empty(''),
  }),
})).required()

const taxDetailsSchema = (required = true) => Joi.array().items(Joi.object({
  country: required ? Joi.string().required().empty('') : Joi.string().empty(''),
  taxNumber: (required ? Joi.string().required().empty('') : Joi.string().empty(''))
    .max(64)
    .messages({
      'string.max': 'The input value must not exceed 64 characters',
    }),
}))

export const validationSchema = Joi.object({
  region: Joi.object({
    region: Joi.string().valid(EWALLET_REGION.EU).required(),
  }).required(),
  businessDetails: Joi.object({
    additionalInfo: Joi.any(),
    searchReferenceId: Joi.any(),
    businessName: Joi.string().required().empty(''),
    businessRegistrationNumber: Joi.string().regex(/((N|n)\/(A|a)|(N|n).(A|a).|(N|n).(A|a)|(N|n)(A|a))/, { invert: true }).required().empty(''),
    tradeName: Joi.string().required().empty(''),
    website: Joi.string().empty(''),
    businessType: Joi.string().required().valid(...Object.keys(businessTypes)),
    documentDetails: businessDetailsDocumentSchema(),
    legalDetails: Joi.object({
      registeredDate: Joi.date().format('YYYY-MM-DD').raw().max('now').empty('').required(),
      registeredCountry: Joi.string().required().empty(''),
    }),
    addresses: Joi.object({
      registeredAddress: addressSchema(),
      isSameAddress: Joi.string().required().valid(...Object.keys(yesOrNo)).empty(''),
      businessAddress: addressSchema(),
    }),
    taxDetails: taxDetailsSchema(),
    // moved - keep in the schema to avoid errors on previously saved data
    stakeholders: Joi.any().optional().strip()
  }),
  stakeholders: Joi.array().items(Joi.object({
    stakeholderType: Joi.string().required().valid(...Object.values(NIUM_STAKEHOLDER_TYPE)).empty(''),
    businessPartner: Joi.object().when('stakeholderType', {
      is: NIUM_STAKEHOLDER_TYPE.INDIVIDUAL,
      then: Joi.optional().allow(),
      otherwise: Joi.object({
        businessName: Joi.string().required().empty(''),
        businessRegistrationNumber: Joi.string().required().empty(''),
        businessEntityType: Joi.string().required().valid(...Object.keys(position)).empty(''),
        sharePercentage: Joi.number().precision(2).when('businessEntityType', {
          is: 'UBO',
          then: Joi.number().precision(2).max(100).min(0).required().empty(''),
          otherwise: Joi.number().precision(2).max(100).min(0).optional().empty(''),
        }),
        legalDetails: Joi.object({
          registeredCountry: Joi.string().required().empty(''),
        }),
        documentDetails: corporateDocumentSchema(true),
        // unused data that can come from the exhaustive corporate details API
        addresses: Joi.any().optional().strip()
      }).required()
    }),
    stakeholderDetails: Joi.object().when('stakeholderType', {
      is: NIUM_STAKEHOLDER_TYPE.CORPORATE,
      then: Joi.optional().allow(null, {}),
      otherwise: Joi.object({
        firstName: Joi.string().required().empty('').max(40).messages({
          'string.max': 'The input value must not exceed 40 characters',
        }),
        middleName: Joi.string().empty('').max(40).messages({
          'string.max': 'The input value must not exceed 40 characters',
        }),
        lastName: Joi.string().required().empty('').max(40).messages({
          'string.max': 'The input value must not exceed 40 characters',
        }),
        nationality: Joi.string().required().empty(''),
        dateOfBirth: Joi.date().format('YYYY-MM-DD').raw().max('now').empty('').required(),
        birthCountry: Joi.string().required().empty(''),
        isPep: Joi.string().required().valid(...Object.keys(yesOrNo)).empty(''),
        address: addressSchema(),
        contactDetails: contactSchema(),
        professionalDetails: professionalDetailsSchema(),
        taxDetails: Joi.any().when('professionalDetails.0.position', {
          is: 'UBO',
          then: taxDetailsSchema(),
          otherwise: taxDetailsSchema(false)
        }),
        documentDetails: stakeholderPersonDocumentSchema()
      }).required()
    }),
  })),
  applicantDetails: Joi.object({
    firstName: Joi.string().required().empty('').max(40).messages({
      'string.max': 'The input value must not exceed 40 characters',
    }),
    middleName: Joi.string().empty('').max(40).messages({
      'string.max': 'The input value must not exceed 40 characters',
    }),
    lastName: Joi.string().required().empty('').max(40).messages({
      'string.max': 'The input value must not exceed 40 characters',
    }),
    nationality: Joi.string().required().empty(''),
    dateOfBirth: Joi.date().format('YYYY-MM-DD').raw().max('now').empty('').required(),
    birthCountry: Joi.string().required().empty(''),
    isPep: Joi.string().required().valid(...Object.keys(yesOrNo)).empty(''),
    contactDetails: contactSchema(),
    address: addressSchema(),
    professionalDetails: professionalDetailsSchema(true),
    documentDetails: applicantPersonDocumentSchema()
  }),
  riskAssessmentInfo: Joi.object({
    totalEmployees: Joi.string().required().valid(...Object.keys(totalEmployees)).empty(''),
    annualTurnover: Joi.string().required().valid(...Object.keys(annualTurnover)).empty(''),
    countryOfOperation: Joi.array().items(
      Joi.string().valid(...Object.keys(countryOfOperationEu)).required(),
    ).min(1).required(),
  }),
  expectedAccountUsage: Joi.object({
    debit: Joi.object({
      monthlyTransactionVolume: Joi.string().valid(...Object.keys(monthlyTransactionVolume)).required(),
      monthlyTransactions: Joi.string().valid(...Object.keys(monthlyTransactions)).required(),
      averageTransactionValue: Joi.string().valid(...Object.keys(averageTransactionValue)).required(),
      topTransactionCountries: Joi.array().items(Joi.string().empty('').required()).required(),
      topBeneficiaries: Joi.array().items(Joi.string().empty('').required()).required(),
    }).required(),
    credit: Joi.object({
      monthlyTransactionVolume: Joi.string().valid(...Object.keys(monthlyTransactionVolume)).required(),
      monthlyTransactions: Joi.string().valid(...Object.keys(monthlyTransactions)).required(),
      averageTransactionValue: Joi.string().valid(...Object.keys(averageTransactionValue)).required(),
      topTransactionCountries: Joi.array().items(Joi.string().empty('').required()).required(),
      topRemitters: Joi.array().items(Joi.string().empty('').required()).required(),
    }).required(),
    intendedUses: Joi.array().items(
      Joi.string().valid(...Object.keys(intendedUseOfAccount)).required(),
    ).min(1).required(),
  }).required(),
  natureOfBusiness: Joi.object({
    industryCodes: Joi.array().items(Joi.string().valid(...Object.keys(industrySector)).required()).min(1).required(),
    // NOTE: Max 300 characters is a requirement from NIUM
    // REF: https://docs.nium.com/apis/docs/cc-eu-required-parameters#natureofbusiness-object
    industryDescription: Joi.string().empty('').max(300).required(),
  }),
  // from previous steps
  tos: Joi.object({
    providerAccept: Joi.boolean().required().valid(true),
    tlpayAccept: Joi.boolean().required().valid(true),
  }),
  brnNumber: Joi.any(),
  brnOther: Joi.any(),
  countryCode: Joi.any(),
  searchReferenceId: Joi.any(),
})